/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import Cookies from 'js-cookie';
import api from 'api';
import moment from 'moment';
import 'moment/locale/pt-br';
import { toast } from 'react-toastify';
import { formatCPF, formatPhone } from 'react-data-formatter';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Header, SideMenu, CancelItem, Search, Dialog } from 'components';
import {
  Observation,
  Container,
  ContentArea,
  Title,
  Div,
  DataArea,
  TextModal,
  DivModal,
  ModalButton,
  Button,
  LoadingArea,
  CountArea,
} from './styles';
import { loadingColor, primary } from 'styles/colorProvider';

function CancelList({ history }) {
  const scrollParent = useRef();

  const [textInput, setTextInput] = useState();
  const [policy, setPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmitAccept, setLoadingSubmitAccept] = useState(false);
  const [loadingSubmitRevert, setLoadingSubmitRevert] = useState(false);
  const [cancelList, setCancelList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();

  const handleRevert = async (e) => {
    setLoadingSubmitRevert(true);
    try {
      await api({
        method: 'POST',
        url: `/refuse-cancel-policy`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: policy?.id,
          description: textInput,
        },

        json: true,
      });

       // Remove a apólice restaurada da lista
      setCancelList(prevList => prevList.filter(item => item.id !== policy?.id));
      setTotalCount(prevCount => prevCount - 1);

      toast.success('Apólice restaurada com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

    } catch (e) {
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } finally {
      setLoadingSubmitRevert(false);
      handleClose();
    }
  };

  const handleAccept = async (e) => {
    setLoadingSubmitAccept(true);
    try {
      await api({
        method: 'POST',
        url: `/backoffice-cancel-policy`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: policy?.id,
          description: textInput,
        },
        json: true,
      });

      toast.success('Apólice cancelada com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
;
    } catch (e) {
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } finally {
      setLoadingSubmitAccept(false);
      handleClose();
    }
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/policies-request-cancel`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          search,
          page: currentPage,
          take: 10,
        },
        json: true,
      });
      setCancelList(resp?.data?.policies);
      setTotalCount(resp?.data?.totalCount);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoading(false);
    } catch (error) {
      history.push('/nao-autorizado');
    }
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/policies-request-cancel`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          search,
          page,
          take: 10,
        },
        json: true,
      });

      const data = cancelList;
      const ndata = data.concat(resp?.data?.policies);

      setCurrentPage(page);
      setCancelList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const handleClose = () => {
    setPolicy(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Dialog open={policy} onClose={handleClose} width="80%" title="Cancelamento de apólice">
        <DivModal>
          <div className="left">
            <TextModal>
              Nome: <span>{policy?.TB_USERS?.name}</span>
            </TextModal>
            <TextModal>
              CPF: <span>{formatCPF(policy?.TB_USERS?.cpf)}</span>
            </TextModal>
            <TextModal>
              Data de nascimento: <span>{moment(policy?.TB_USERS?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</span>
            </TextModal>
            <TextModal>
              Sexo:
              {policy?.TB_USERS?.gender === 1 && <span> Masculino</span>}
              {policy?.TB_USERS?.gender !== 1 && <span> Feminino</span>}
            </TextModal>
            <TextModal>
              E-mail: <span>{policy?.TB_USERS?.email}</span>
            </TextModal>
            <TextModal>
              Telefone: <span>{formatPhone(policy?.TB_USERS?.phone)}</span>
            </TextModal>
          </div>

          <div className="right">
            <TextModal color>
              Motivo:<span> {policy?.history?.TB_CONSTANT_CANCELLATION_REASON?.name}</span>
            </TextModal>
            <TextareaAutosize
              minRows={1}
              disabled={true}
              style={{
                padding: 10,
                fontFamily: 'Nunito',
                fontSize: 14,
                width: '90%',
                height: '110px',
                borderRadius: 5,
                borderColor: '#d1d2d4',
              }}
            >
              {policy?.history?.motive_detail}
            </TextareaAutosize>
          </div>
        </DivModal>

        <Observation>
          <TextModal color>Observações:</TextModal>
          <TextareaAutosize
            minRows={1}
            placeholder="Deixe sua observação aqui..."
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            style={{
              padding: 10,
              fontFamily: 'Nunito',
              fontSize: 14,
              width: '95%',
              height: '60px',
              borderRadius: 5,
              borderColor: '#d1d2d4',
            }}
          />
        </Observation>

        <ModalButton>
          <Button style={{ background: 'transparent', border: '1.5px solid #62ec83' }} onClick={handleClose}>
            Voltar
          </Button>
          <Button style={{ background: 'transparent', border: '1.5px solid #62ec83' }} onClick={handleAccept}>
            {loadingSubmitAccept ? <ReactLoading color="#62ec83" height={20} width={20} type="spin" /> : 'Concluir cancelamento'}
          </Button>
          <Button style={{ background: '#62ec83', border: 'none' }} onClick={handleRevert}>
            {loadingSubmitRevert ? <ReactLoading color="#ffff" height={20} width={20} type="spin" /> : 'Reverter cancelamento'}
          </Button>
        </ModalButton>
      </Dialog>

      <Header />
      <Container>
        <SideMenu />

        <ContentArea>
          <Div>
            <Title>SOLICITAÇÕES DE CANCELAMENTO</Title>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => {
                setSearch('');
                loadData();
              }}
              onConfirm={() => loadData()}
            />
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading &&
                  cancelList?.map((policy) => (
                    <CancelItem policy={policy} onClick={() => setPolicy(policy)} open={policy} onClose={handleClose} />
                  ))}
              </div>
            </InfiniteScroll>

            {!loading && !cancelList?.length && <p>Nenhuma solicitação encontrada</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {cancelList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default CancelList;
